import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import API_BASE_URL from '../../utils/config';

export const problemStatusApi = createApi({
  reducerPath: "problemStatusApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_BASE_URL}/api/code/`,
  }),
  endpoints: (builder) => ({
    getProblemStatus: builder.query({
      query: (id) => `status/${id}`,
    }),
  }),
});

export const { useGetProblemStatusQuery } = problemStatusApi