// OCRService.ts
import axios from 'axios';

const apiKey = 'AIzaSyCFpEq67jyEWCYqXrgyBX0UDT0B3pKA-w0'; // Replace with your API key

interface OCRResponse {
  responses: any;
  fullTextAnnotation: {
    text: string;
  };
}

const extractTextFromImage = async (image: string): Promise<string | null> => {
  const apiUrl = 'https://vision.googleapis.com/v1/images:annotate?key=' + apiKey;

  const requestBody = {
    requests: [
      {
        image: {
          content: image,
        },
        features: [
          {
            type: 'TEXT_DETECTION',
          },
        ],
      },
    ],
  };

  try {
    const response = await axios.post<OCRResponse>(apiUrl, requestBody);
    return response.data.responses[0]?.fullTextAnnotation?.text || null;
  } catch (error) {
    console.error('Error extracting text:', error);
    return null;
  }
};

export default extractTextFromImage;
