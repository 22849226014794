// syntax.tsx

import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

const Syntax = ({ onClose }: { onClose: () => void }) => {
    const [keywords, setKeywords] = useState([
        { id: 1, label: "And", value: "and" },
        { id: 2, label: "Class", value: "class" },
        { id: 3, label: "Else", value: "else" },
        { id: 4, label: "False", value: "false" },
        { id: 5, label: "For", value: "for" },
        { id: 6, label: "Fun", value: "fun" },
        { id: 7, label: "If", value: "if" },
        { id: 8, label: "Nil", value: "nil" },
        { id: 9, label: "Or", value: "or" },
        { id: 10, label: "Print", value: "print" },
        { id: 11, label: "Return", value: "return" },
        { id: 12, label: "Super", value: "super" },
        { id: 13, label: "This", value: "this" },
        { id: 14, label: "True", value: "true" },
        { id: 15, label: "Var", value: "var" },
        { id: 16, label: "While", value: "while" },
    ]);

    useEffect(() => {
        // Load values from localStorage on component mount
        const storedSyntax = localStorage.getItem("syntax");
        if (storedSyntax) {
            const storedKeywords = storedSyntax.split("\n").map((value, index) => ({
                id: index + 1,
                label: keywords[index].label,
                value: value.trim(),
            }));
            setKeywords(storedKeywords);
        }
    }, []);

    const handleValueChange = (id: number, newValue: string) => {
        setKeywords((prevKeywords) =>
            prevKeywords.map((keyword) =>
                keyword.id === id ? { ...keyword, value: newValue } : keyword
            )
        );
    };

    const handleSave = () => {
        const syntaxString = keywords.map(keyword => keyword.value).join('\n');
        localStorage.setItem("syntax", syntaxString);
        onClose();
        console.log("Syntax is: ", localStorage.getItem("syntax"));
        toast.success("Saved!");
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 ">
            <div className="bg-primary p-6 w-[90vh] rounded-lg shadow-lg flex flex-col">
                <div className="w-full ">
                    <div className="flex flex-row justify-between items-start">
                        <h2 className="text-white text-lg font-semibold">Syntax Keywords</h2>
                        <button
                            onClick={onClose}
                            className="p-2 shadow-md px-8 border rounded-lg bg-red-500 text-white">
                            Close
                        </button>
                    </div>
                    <div className="flex mt-5">
                        <div className="w-1/2 pr-4">
                            <ul>
                                {keywords.slice(0, 8).map((keyword) => (
                                    <li key={keyword.id} className="mb-3 flex items-center">
                                        <label className="text-white w-1/2">{keyword.label}</label>
                                        <input
                                            type="text"
                                            value={keyword.value}
                                            onChange={(e) =>
                                                handleValueChange(keyword.id, e.target.value)
                                            }
                                            className="p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-500"
                                        />
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="w-1/2 pl-4">
                            <ul>
                                {keywords.slice(8).map((keyword) => (
                                    <li key={keyword.id} className="mb-3 flex items-center">
                                        <label className="text-white  w-1/2">{keyword.label}</label>
                                        <input
                                            type="text"
                                            value={keyword.value}
                                            onChange={(e) =>
                                                handleValueChange(keyword.id, e.target.value)
                                            }
                                            className="p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-500"
                                        />
                                    </li>
                                ))}

                            </ul>
                        </div>

                    </div>
                    <div className="flex mt-5 flex-row justify-center">
                        <button
                            onClick={handleSave}
                            className="p-2 shadow-md px-8 border rounded-lg bg-green-500 text-white">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Syntax;
