import React from 'react'
import ProblemEditor from "../components/ProblemEditor";
const Playground = () => {
    return (
        <div>
            <ProblemEditor isTesting={true} />
        </div>

    )
}

export default Playground