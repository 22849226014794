import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../store/store";

export default function ProblemList() {
  const problems = useSelector((state: RootState) => state.problem.problems);
  const user = useSelector((state: RootState) => state.auth.user);
  const navigate = useNavigate();
  const loading = useSelector((state: RootState) => state.problem.loading);

  const isItSolved = (solvedArr: any) => {
    if (!solvedArr) return false;
    return solvedArr.includes(user?._id);
  };

  const [selectedParent, setSelectedParent] = useState<number | null>(null);

  // Function to check if all child problems under a main problem are solved
  const isMainProblemCompleted = (parentId: number) => {
    const childProblems = problems.filter((problem) => problem.parent === parentId);
    return childProblems.every((childProblem) => isItSolved(childProblem.whoSolved));
  };

  return (
    <div className="space-y-8">
      {!loading ? (
        selectedParent === null ? (
          <div className="flex flex-col space-y-10 text-white">
            {/* NPA COMPILER DESIGN */}
            <div
              className={`h-[30vh] flex  justify-between p-8 rounded-md shadow-md hover:scale-105 transition-all duration-300 ease-in-out cursor-pointer bg-primary `}
              onClick={() => setSelectedParent(1)}
            >
              <div>
                <h2 className="text-xl mb-8 m-0 hover:underline cursor-pointer capitalize">
                  NPA COMPILER DESIGN
                </h2>
                <span className="text-sm m-0 cursor-pointer">
                The NPA Compiler Design Course offers an in-depth look into building compilers specifically for the NPA programming language.
                Covering key concepts like syntax analysis and code generation, it blends theory with practical projects, making it ideal for anyone keen on deepening their understanding of compiler construction in a hands-on environment.
                </span>
              </div>
              <div className="flex items-start">
                <span className={`whitespace-nowrap text-${isMainProblemCompleted(1) ? "white bg-green" : "white"}-600 font-semibold ml-2 p-1 px-3 border border-slate-300 rounded-sm text-white `}>
                  {isMainProblemCompleted(1) ? "COMPLETED" : "NOT COMPLETED"}
                </span>
              </div>
            </div>


            {/* NPA CERTIFICATION */}
            <div
              className={`h-[30vh] flex justify-between p-8 rounded-md shadow-md hover:scale-105 transition-all duration-300 ease-in-out cursor-pointer bg-primary `}
              onClick={() => setSelectedParent(2)}
            >
              <div>
                <h2 className="text-xl m-0 mb-8 hover:underline cursor-pointer capitalize">
                  NPA CERTIFICATION
                </h2>
                <span className="text-sm m-0  cursor-pointer">
                The NPA Certification is a student-created credential designed to master the innovative NPA programming language. 
                Focused on practical experience and community learning, it offers a fun and accessible path for both novice and seasoned coders 
                to develop valuable coding skills and connect with like-minded peers.
                </span>
              </div>
              <div className="flex items-start">
                <span className={`whitespace-nowrap text-${isMainProblemCompleted(2) ? "white bg-green" : "white"}-600 font-semibold ml-2 p-1 px-3 border border-slate-300 rounded-sm text-white `}>
                  {isMainProblemCompleted(2) ? "COMPLETED" : "NOT COMPLETED"}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <>
            <button
              className="p-2 px-4 mb-4 bg-black text-white rounded shadow cursor-pointer"
              onClick={() => setSelectedParent(null)}
            >
              Back
            </button>
            {problems
              .filter((problem) => problem.parent === selectedParent)
              .map((item, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between bg-[whitesmoke] p-4 px-8 rounded shadow-md hover:scale-105 transition-all duration-300 ease-in-out"
                  onClick={() => navigate(`/problem/${item._id}`)}
                >
                  <div>
                    <h2 className="text-xl m-0 hover:underline cursor-pointer capitalize">
                      {item.title}
                    </h2>
                    <p
                      className="m-0 max-w-2xl mt-2"
                      style={{ wordSpacing: "5px" }}
                    >
                      {item.desc}
                    </p>
                  </div>

                  <button className={`p-2 px-4 outline-none rounded shadow text-white ${isItSolved(item.whoSolved) ? "bg-green-600 font-semibold line-through" : "bg-black hover:bg-white hover:text-black hover:border"}`}>
                    {isItSolved(item.whoSolved) ? 'Solved' : "Solve Now"}

                  </button>
                </div>
              ))}
          </>
        )
      ) : (
        [0, 1, 2].map((item) => (
          <div
            key={item}
            className="border border-blue-300 shadow rounded-md p-4 max-w-4xl w-full mx-auto"
          >
            <div className="animate-pulse flex items-center space-x-4">
              <div className="flex-1 space-y-6 py-1">
                <div className="h-3 bg-slate-700 rounded max-w-md"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-5 gap-4">
                    <div className="h-2 bg-slate-700 rounded col-span-3 max-w-xs"></div>
                  </div>
                </div>
              </div>
              <div className="h-8 bg-slate-500 rounded w-24"></div>
            </div>
          </div>
        ))
      )}
    </div>
  );
}
