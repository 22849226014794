import React, { ChangeEvent, useState } from 'react';
import extractTextFromImage from './OCRService';
import { IoIosArrowBack } from "react-icons/io";

interface ImageToTextConverterProps {
  onTextExtracted: (text: string) => void;
}

const ImageToTextConverter: React.FC<ImageToTextConverterProps> = ({ onTextExtracted }) => {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [showContent, setShowContent] = useState(false);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (selectedImage) {
      const reader = new FileReader();
      reader.readAsDataURL(selectedImage);
      reader.onloadend = async () => {
        const base64data = reader.result?.toString().split(',')[1];
        if (base64data) {
          const text = await extractTextFromImage(base64data);
          onTextExtracted(text || ''); // Pass the extracted text to the parent component
          setShowContent(false); // Hide content after uploading
        }
      };
    }
  };

  return (
    <div className="mx-4 relative bg-white rounded-md shadow-md">
      {showContent && (
        <div className="absolute top-[-100px] left-0 right-0 z-50 w-[30vh] bg-white rounded-md shadow-md">
          <div className="text-black p-2">
            <input
              type="file"
              id="imageUpload"
              accept="image/*"
              className="border rounded-md w-full"
              onChange={handleFileChange}
            />
            <button
              onClick={handleUpload}
              className='bg-primary text-white p-2 px-5 mt-2 w-full rounded hover:bg-green-600 focus:outline-none focus:ring focus:border-blue-300'
            >
              Upload
            </button>
          </div>
        </div>
      )}
      <button
        onClick={() => setShowContent(!showContent)}
        className='bg-primary text-white p-2 px-5 mt-2 rounded hover:bg-green-600 focus:outline-none focus:ring focus:border-blue-300'
      >
        Scan
      </button>
    </div>
  );
};

export default ImageToTextConverter;
